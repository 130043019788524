import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import config from '@configFile';
import { DealPostType } from '@types';
import { formatPrice, addAffiliateTagToLink } from '@utils/index';
import { getExtraDeal } from '@utils/extraDealMapper';
import MonetizingLink from '@components/MonetizingLink';
import { LineConfigType } from '@components/DealSummary/components/DiscountReceipt';
import Line from '../Line';

interface Props {
  receiptDeal: DealPostType;
  tag?: string;
  showOr?: boolean;
}

const ExtraDealReceipt = ({
  receiptDeal: dealData,
  tag = config.AFFILIATE_TAGS.HOW_TO_GET_DEAL,
  showOr = true
}: Props) => {
  const extraDeal = getExtraDeal(dealData);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  // if there's an extra deal show with receipts
  // Subscription summary

  const x = extraDeal?.x || extraDeal?.buyX || extraDeal?.getX;

  if (
    !extraDeal ||
    !x ||
    (x && x === 1) ||
    (!extraDeal.saveY &&
      !extraDeal.forPriceOfY &&
      !(extraDeal.saveYDiscount && extraDeal.saveYDiscount > 0))
  ) {
    return null;
  }

  const lineConfig: LineConfigType = [];

  lineConfig.push({
    key: 'items',
    left: `Items (${x}):`,
    right: `${formatPrice(x * dealData.currentPrice)}`
  });

  lineConfig.push({
    key: 'shipping',
    left: 'Shipping & handling:',
    right: '$0.00'
  });

  if (
    extraDeal.saveY &&
    !(extraDeal.saveYDiscount && extraDeal.saveYDiscount > 0)
  ) {
    lineConfig.push({
      key: 'promo',
      left: dealData.extraDealLink ? (
        <MonetizingLink
          tag={tag}
          clickType="receipt-coupon-link"
          href={addAffiliateTagToLink(dealData.extraDealLink, tag)}
          style={{
            fontSize: '12px',
            lineHeight: '16px',
            color: isDarkMode ? '#d0bcfb' : '#0000EE',
            textDecoration: 'underline'
          }}
          sx={{
            '@media (max-width: 540px)': {
              fontSize: '12px !important',
              lineHeight: '16px !important'
            }
          }}
        >
          {extraDeal.extraDeal}:
        </MonetizingLink>
      ) : (
        `${extraDeal.extraDeal}:`
      ),

      right: `-$${extraDeal.saveY.toFixed(2)}`
    });
  }

  if (
    extraDeal.forPriceOfY &&
    extraDeal.multibuyDiscount &&
    !(extraDeal.saveYDiscount && extraDeal.saveYDiscount > 0)
  ) {
    lineConfig.push({
      key: 'promo',
      left: dealData.extraDealLink ? (
        <MonetizingLink
          tag={tag}
          clickType="receipt-coupon-link"
          href={addAffiliateTagToLink(dealData.extraDealLink, tag)}
          style={{
            fontSize: '12px',
            lineHeight: '16px',
            color: isDarkMode ? '#d0bcfb' : '#0000EE',
            textDecoration: 'underline'
          }}
          sx={{
            '@media (max-width: 540px)': {
              fontSize: '12px !important',
              lineHeight: '16px !important'
            }
          }}
        >
          {extraDeal.extraDeal}:
        </MonetizingLink>
      ) : (
        `${extraDeal.extraDeal}:`
      ),
      right: `-$${extraDeal.multibuyDiscount.toFixed(2)}`
    });
  }

  if (extraDeal.saveYDiscount && extraDeal.saveYDiscount > 0) {
    lineConfig.push({
      key: 'promo',
      left: dealData.extraDealLink ? (
        <MonetizingLink
          tag={tag}
          clickType="receipt-coupon-link"
          href={addAffiliateTagToLink(dealData.extraDealLink, tag)}
          style={{
            fontSize: '12px',
            lineHeight: '16px',
            color: isDarkMode ? '#d0bcfb' : '#0000EE',
            textDecoration: 'underline'
          }}
          sx={{
            '@media (max-width: 540px)': {
              fontSize: '12px !important',
              lineHeight: '16px !important'
            }
          }}
        >
          {extraDeal.extraDeal}:
        </MonetizingLink>
      ) : (
        `${extraDeal.extraDeal}:`
      ),
      right: `-$${extraDeal.saveYDiscount.toFixed(2)}`
    });
  }

  if (
    extraDeal.maxSSDiscount &&
    extraDeal.maxSSDiscount > 0 &&
    dealData.maxSs &&
    dealData.maxSs > 0
  ) {
    lineConfig.push({
      key: 'ss',
      left: `Subscribe & Save (${dealData.maxSs}%):`,
      right: `-$${extraDeal.maxSSDiscount.toFixed(2)}`
    });
  }

  let eachPriceStr = '';

  if (
    dealData.qty &&
    dealData.qtyTerm &&
    dealData.qty > 1 &&
    dealData.qtyTerm !== 'each' &&
    extraDeal.eachPrice &&
    x
  ) {
    eachPriceStr = ` or ${formatPrice(
      extraDeal.extraDealPrice / x / dealData.qty
    )}/${dealData.qtyTerm}`;
  }

  lineConfig.push({
    key: 'total',
    left:
      extraDeal.eachPrice && x
        ? `Order total (works out to ${formatPrice(
            extraDeal.extraDealPrice / x
          )}/each${eachPriceStr}):`
        : `Order total:`,
    right: `$${extraDeal.extraDealPrice.toFixed(2)}`,
    isTotal: true
  });

  return (
    <>
      {showOr ? (
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            textAlign: 'center',
            fontStyle: 'italic',
            margin: '0.35em'
          }}
        >
          Or
        </Typography>
      ) : null}
      <Box
        sx={{
          margin: '10px auto 0 auto',
          padding: '10px 3%',
          fontSize: '12px',
          lineHeight: '16px',
          border: '1px solid #ddd',
          maxWidth: '600px'
        }}
      >
        {dealData.ss ? (
          <Box
            sx={{
              fontWeight: 700,
              marginBottom: '22px',
              fontSize: '12px',
              lineHeight: '16px',
              width: '50%',
              // if less than 375px, make width 100%
              '@media (max-width: 375px)': {
                width: '100%',
                marginBottom: '8px'
              }
            }}
          >
            Subscription Summary
          </Box>
        ) : null}
        {lineConfig.map((line) => (
          <Line {...line} />
        ))}
      </Box>
    </>
  );
};

export default ExtraDealReceipt;
